function setCameraBinding(element) {
  document.getElementById(element).setAttribute('set_bind', 'true');
}

function resetViewpoint(x, d) {

  document.getElementById('x3d').runtime.resetView();

  var orthoViewpoints = document.getElementById("x3d").getElementsByTagName("OrthoViewpoint");
  for (var i=0; i<orthoViewpoints.length; i++){
    if (document.getElementById("x3d").getElementsByTagName("OrthoViewpoint")[i].id === 'viewpointOben'){
      document.getElementById("x3d").getElementsByTagName("OrthoViewpoint")[i].setFieldValue('position', {x: 0, y: (d), z: 0});
      document.getElementById("x3d").getElementsByTagName("OrthoViewpoint")[i].setFieldValue('fieldOfView', [(-x), (-x), (x), (x)]);

    }else if(document.getElementById("x3d").getElementsByTagName("OrthoViewpoint")[i].id === 'viewpointLinks'){
      document.getElementById("x3d").getElementsByTagName("OrthoViewpoint")[i].setFieldValue('position', {x: -d, y: 0, z: 0});
      document.getElementById("x3d").getElementsByTagName("OrthoViewpoint")[i].setFieldValue('fieldOfView', [(-x), (-x), (x), (x)]);

    }else if(document.getElementById("x3d").getElementsByTagName("OrthoViewpoint")[i].id === 'viewpointFront'){
        document.getElementById("x3d").getElementsByTagName("OrthoViewpoint")[i].setFieldValue('position', {x: 0, y: 0, z: d});
        document.getElementById("x3d").getElementsByTagName("OrthoViewpoint")[i].setFieldValue('fieldOfView', [(-x), (-x), (x), (x)]);
    }else{
      document.getElementById("x3d").getElementsByTagName("OrthoViewpoint")[i].setFieldValue('position', {x: -d, y: 0.4*d, z: d});
      document.getElementById("x3d").getElementsByTagName("OrthoViewpoint")[i].setFieldValue('fieldOfView', [(-x), (-x), (x), (x)]);
    }
  }


}
